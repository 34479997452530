<template>
  <div id="favorite-authors">
    <b-alert :show="!favoriteAuthors.length" variant="success"
      >You have not followed anyone yet! Follow, so that you don't miss anything
      from your favorite Author!</b-alert
    >
    <b-row class="mx-0">
      <b-col cols="12" lg="9">
        <div v-if="favoriteAuthors.length">
          <b-card
            :img-src="
              author.image
                ? `data:image/png;base64,${author.image}`
                : require('../assets/Avatar.png')
            "
            :img-left="!isMobile"
            :img-top="isMobile"
            img-width="250"
            img-height="350"
            class="favorite-card m-3"
            align="left"
            v-for="author in favoriteAuthors"
            :key="author._id"
          >
            <b-card-text>
              <div :style="isMobile && 'float: right'">
                <b-button
                  size="sm"
                  :variant="isGlorified(author)"
                  @click="glorify(author)"
                >
                  <b-spinner v-if="isGlorifying" small></b-spinner>
                  <b-icon-trophy v-else aria-label="trophy"></b-icon-trophy
                  >&nbsp;
                  <b-badge variant="light">{{
                    author.glorified.length
                  }}</b-badge>
                </b-button>
                <b-button
                  variant="primary"
                  disabled
                  class="ml-2"
                  size="sm"
                  title="Followed By"
                >
                  <b-icon-people-fill> </b-icon-people-fill>&nbsp;
                  <b-badge variant="light">{{
                    author.followedBy.length
                  }}</b-badge>
                </b-button>
                <b-button
                  size="sm"
                  class="ml-2"
                  @click="followWriter(author)"
                  :title="isFollowing(author) ? 'Unfollow' : 'Follow'"
                  :variant="
                    isFollowing(author) ? 'secondary' : 'outline-primary'
                  "
                  ><b-spinner v-if="isFollowProgress" small></b-spinner>
                  <template v-else>
                    <b-icon-person-x
                      v-if="isFollowing(author)"
                      aria-hidden="true"
                    ></b-icon-person-x>
                    <b-icon-person-plus
                      v-else
                      aria-hidden="true"
                    ></b-icon-person-plus>
                  </template>
                </b-button>
              </div>
              <br v-if="isXSScreen" />
              <br v-if="isXSScreen" />
              <router-link
                class="author-name__link"
                :to="`/profile/${author._id}`"
                ><span class="author__name">{{
                  author.name
                }}</span></router-link
              >
              <span class="author__age">, {{ author.dob | ageFilter }}</span>
              <p class="mt-4"><b>About: </b> {{ author.about }}</p>
              <p class="mt-4"><b>Gender: </b> {{ author.gender }}</p>
              <p class="mt-4"><b>Hobby: </b> {{ author.hobby }}</p>
              <p class="mt-4"><b>Languages: </b> {{ author.languages }}</p>
            </b-card-text>
          </b-card>
        </div></b-col
      >
      <b-col cols="3" class="px-1"><TrendingAuthors></TrendingAuthors></b-col
    ></b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BAlert,
  BCard,
  BCardText,
  BButton,
  BBadge,
  BIconTrophy,
  BIconPeopleFill,
  BIconPersonX,
  BIconPersonPlus,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    TrendingAuthors: () =>
      import(/* webpackPrefetch: true */ "../components/TrendingAuthors.vue"),
    BAlert,
    BCard,
    BCardText,
    BButton,
    BBadge,
    BIconTrophy,
    BIconPeopleFill,
    BIconPersonX,
    BIconPersonPlus,
    BSpinner,
  },

  data() {
    return {
      favoriteAuthors: [],
      glorifiedAlready: false,
      isMobile: false,
      isXSScreen: false,
      isFollowProgress: false,
      isGlorifying: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),
  },

  async created() {
    this.isMobile = window.screen.width < 1024;
    this.isXSScreen = window.screen.width < 768;
    try {
      this.favoriteAuthors = await this.getMyFollowList({
        authorList: this.userDetails.followingAuthors,
      });
    } catch (e) {
      if (e.response.status === 401) {
        this.signOut();
      }
    }
  },

  methods: {
    ...mapActions([
      "getMyFollowList",
      "followAuthor",
      "glorifyUser",
      "sendNotification",
      "signOut",
    ]),

    isFollowing(author) {
      return author.followedBy?.some((item) => item.id == this.userDetails.id);
    },

    isGlorified(author) {
      return author.glorified?.indexOf(this.userDetails.id) > -1
        ? "warning"
        : "outline-warning";
    },

    async glorify(author) {
      if (this.isGlorifying) {
        return;
      }

      try {
        // let date = new Date();

        this.isGlorifying = true;
        await this.glorifyUser({
          id: author._id,
          userId: this.userDetails.id,
        });

        let ind = author.glorified?.indexOf(this.userDetails.id);

        if (~ind) {
          author.glorified.splice(ind, 1);
        } else {
          author.glorified.push(this.userDetails.id);
        }

        this.$bvToast.toast(
          `You have ${~ind ? "un-glorified" : "glorified"} ${author.name}!`,
          {
            title: `${~ind ? "Un-Glorified" : "Glorified"}`,
            autoHideDelay: 2000,
            variant: `${~ind ? "danger" : "warning"}`,
            solid: true,
          }
        );

        if (!this.glorifiedAlready) {
          this.sendNotification({
            payload: {
              message: "has glorified you!",
              userId: this.userDetails.id,
              userName: this.userDetails.name,
              time: new Date(),
              id: author._id,
            },
          });
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
        }
      } finally {
        this.isGlorifying = false;
      }
    },

    // alert before unfollow
    async followWriter(author) {
      if (this.isFollowProgress) {
        return;
      }

      try {
        this.isFollowProgress = true;
        let res = await this.followAuthor({
          payload: {
            user: {
              id: this.userDetails.id,
              name: this.userDetails.name,
            },
            author: {
              id: author._id,
              name: author.name,
            },
          },
        });

        author.followedBy = res.followedBy;

        let isFollowing = this.isFollowing(author);

        this.$bvToast.toast(
          `You have ${isFollowing ? "followed" : "unfollowed"} ${author.name}!`,
          {
            title: `${isFollowing ? "Followed" : "UnFollowed"}`,
            autoHideDelay: 2000,
            variant: `${isFollowing ? "warning" : "danger"}`,
            solid: true,
          }
        );

        if (isFollowing) {
          this.sendNotification({
            payload: {
              message: "has followed you!",
              userId: this.userDetails.id,
              userName: this.userDetails.name,
              time: new Date(),
              id: author._id,
            },
          });
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
        }
      } finally {
        this.isFollowProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite-card {
  border: 1px solid green;
}
.author {
  &__name {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
  }
  &__age {
    font-size: 1.5rem;
  }
}
</style>
